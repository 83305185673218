.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*****************custom***************/
.img {

  background-size: cover;
  background-image: url('../public/assets/img/carousel-1.jpg');

}

.img2 {
  background-image: url('../public/assets/img/carousel-2.jpg');
  background-size: cover;

}

.img3 {
  background-image: url('../public/assets/img/carousel-3.jpg');
  background-size: cover;

}

.aside {
  background-color: #fff;
}

.main {
  height: 590px;

}

.signimg {
  background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg');
  background-size: cover;

}

/* 
.btn {
  float: right;
} */
.btnsize {
  margin-top: 20px;
}

.add {
  float: left;
}

.title {
  text-align: start;
  margin-left: 26px;
}
.box{
      width: 150px;
}
.box1{
    text-align: left;
    background: #596CFF;
    border: 1px solid gray;
    border-radius: 9px;
    /* padding: 10px; */
}
.text{
  margin-left: 6px;
  color: #fff;
}
.ser{
      margin-left: 18px;
    margin-right: 18px;
}
.gym{
  margin-top: 5px;
  
}
.logo{
  margin-top: 5px;
}
.up{
          float: right;
    height: 21px;
    width: 32px;
}
.file{
  display: none;
}
.camera{
  cursor: pointer;
    margin-left: 35px;
    /* margin-top: -107px; */
  
    top: 152px;
    right: 82px;
}
.img5{
      margin-top: -19px;
}
.img8{
      width: 100px;
    height: 100px;
    margin-top: -56px;
}
.info{
 text-align: center;
  color: #fff;
 /*  text-decoration:underline; */
}
.msg{
  color: red;
}
